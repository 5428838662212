var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('search-filter',{attrs:{"items":_vm.movements,"keyTitleForAlphabeticalSort":"employee","additionalSortOptions":_vm.additionalSortOptions,"selectedView":'list',"selectedFilters":_vm.selectedFilters,"tableColumns":_vm.tableColumns,"isActionColumnEnabled":false,"allowAdd":false},scopedSlots:_vm._u([{key:"gridView",fn:function(ref){
var item = ref.item;
return [_c('card-movement',{attrs:{"movement":item}})]}},{key:"listView_cell_employee",fn:function(ref){
var item = ref.item;
return [_c('b-media',{attrs:{"vertical-align":"center"},scopedSlots:_vm._u([{key:"aside",fn:function(){return [_c('b-avatar',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.bottom",value:(item.logs[0].by._display),expression:"item.logs[0].by._display",modifiers:{"hover":true,"bottom":true}}],attrs:{"size":"32","src":item.logs[0].by.avatar?item.logs[0].by.avatar.url:null,"text":_vm.avatarText(item.logs[0].by.firstName + ' ' + item.logs[0].by.name),"variant":"light-primary"}})]},proxy:true}],null,true)},[_c('b-link',{staticClass:"font-weight-bold d-block text-nowrap"},[_vm._v(" "+_vm._s(item.title)+" ")])],1)]}},{key:"listView_cell_startDate",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("moment")(item.startDate,'LL'))+" ")]}},{key:"listView_cell_startPlace",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.startPlace._display)+" ")]}},{key:"listView_cell_endPlace",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.endPlace._display)+" ")]}},{key:"listView_cell_distance",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.distance(item.distance))+" ")]}},{key:"listView_cell_estimatedCost",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.currency(item._mileageAllowanceCost))+" ")]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }