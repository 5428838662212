<template>
  <generic-content
      :isLinkable="false"
      :isEditable="false"
      :isDownloadable="false"
      :isDeletable="false"
  >
    <template #iconContent>
      <b-avatar
          size="32"
          :src="movement.logs[0].by.avatar?movement.logs[0].by.avatar.url:null"
          :text="avatarText(movement.logs[0].by.firstName + ' ' + movement.logs[0].by.name)"
          v-b-tooltip.hover.bottom="movement.logs[0].by._display"
          variant="light-primary"
          class="m-auto"
      />
    </template>

    <template #bodyContent>
      <h6 class="transaction-title text-truncate">
        {{ movement.title }}
      </h6>
      <div class="d-flex justify-content-between text-truncate">
        <small>
          <i>{{ movement.startDate|moment('LL')}}</i>
        </small>
          <small>{{ distance(movement.distance) }}</small>
      </div>
    </template>

  </generic-content>
</template>

<script>
import { ref, computed, watch } from '@vue/composition-api'
import { avatarText, distance } from '../../../utils/filter'

import GenericContent from './Content'

export default {
  components: {
    GenericContent
  },
  props: {
    movement: {
      type: Object,
      default: () => {}
    }
  },
  setup (props) {
    // ------------------------------------------------
    // Data
    // ------------------------------------------------

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------
    return {
      // Components

      // Data
      avatarText,
      distance,

      // Computed

      // Methods
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {},
  methods: {},
  mounted () {

  },
  created () {
  }
}
</script>

<style lang="scss">

</style>