import { ref, computed, watch } from '@vue/composition-api'
import { avatarText, distance, currency } from '../../../utils/filter'

import store from '@/store'
import i18n from '@/libs/i18n'
import useAPI from '../../../utils/useAPI'
import SearchFilter from '../../../components/searchFilter/SearchFilter'
import CardMovement from '../../../components/card/Movement'

export default {
  components: {
    SearchFilter,
    CardMovement
  },
  props: {},
  setup () {
    // ------------------------------------------------
    // Data
    // ------------------------------------------------
    const additionalSortOptions = ref([])
    const tableColumns = ref([
      { key: 'employee', label: i18n.tc('employee', 1), sortable: true },
      { key: 'startDate', label: i18n.t('date'), sortable: true },
      { key: 'startPlace', label: i18n.t('startPlace'), sortable: false },
      { key: 'endPlace', label: i18n.t('endPlace'), sortable: false },
      { key: 'distance', label: i18n.t('distance'), sortable: true },
      { key: 'estimatedCost', label: i18n.t('estimatedCost'), sortable: true },
    ])
    const selectedFilters = ref([])

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------
    const movements = computed(() => {
      return store.getters['event/getMovements']
    })

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------
    const {
      fetchEvents,
    } = useAPI()

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------
    fetchEvents()

    return {
      // Components
      avatarText,
      distance,
      currency,

      // Data
      additionalSortOptions,
      tableColumns,
      selectedFilters,

      // Computed
      movements,

      // Methods
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {},
  methods: {},
  mounted () {
  },
  created () {
  }
}