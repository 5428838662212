<template>
  <search-filter
      :items="movements"
      keyTitleForAlphabeticalSort="employee"
      :additionalSortOptions="additionalSortOptions"
      :selectedView="'list'"
      :selectedFilters="selectedFilters"

      :tableColumns="tableColumns"
      :isActionColumnEnabled="false"
      :allowAdd="false"
  >
    <!--      Grid view-->
    <template v-slot:gridView="{item}">
      <card-movement
          :movement="item"
      />
    </template>

    <!--      List view-->

    <!--          Cell employee-->
    <template #listView_cell_employee="{item}">
      <b-media vertical-align="center">
        <template #aside>
          <b-avatar
              size="32"
              :src="item.logs[0].by.avatar?item.logs[0].by.avatar.url:null"
              :text="avatarText(item.logs[0].by.firstName + ' ' + item.logs[0].by.name)"
              v-b-tooltip.hover.bottom="item.logs[0].by._display"
              variant="light-primary"
          />
        </template>
        <b-link
            class="font-weight-bold d-block text-nowrap"
        >
          <!--              {{ item.logs[0].by._display }}-->
          {{ item.title }}
        </b-link>
      </b-media>
    </template>

    <!--      Cell Date-->
    <template #listView_cell_startDate="{item}">
      {{ item.startDate|moment('LL') }}
    </template>

    <!--      Cell start place-->
    <template #listView_cell_startPlace="{item}">
      {{ item.startPlace._display }}
    </template>

    <!--      Cell end place-->
    <template #listView_cell_endPlace="{item}">
      {{ item.endPlace._display }}
    </template>

    <!--      Cell distance-->
    <template #listView_cell_distance="{item}">
      {{ distance(item.distance) }}
    </template>

    <!--      Cell estimated cost-->
    <template #listView_cell_estimatedCost="{item}">
      {{ currency(item._mileageAllowanceCost) }}
    </template>
  </search-filter>
</template>

<script>
import { ref, computed, watch } from '@vue/composition-api'
import mixinMovements from './mixinMovements'

export default {
  components: {},
  mixins: [mixinMovements],
  props: {},
  setup () {
    // ------------------------------------------------
    // Data
    // ------------------------------------------------

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------
    return {
      // Components

      // Data

      // Computed

      // Methods
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {},
  methods: {},
  mounted () {
  },
  created () {
  }
}
</script>

<style lang="scss">

</style>